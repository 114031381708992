import React from 'react';
import PropTypes from 'prop-types';
import { propTypes as linkPropTypes } from '../../../components/01_atoms/Link';
import Link from '../../Link';
import DefaultPicture, {
  propTypes as pictureProps,
} from '../../../components/01_atoms/DefaultPicture';

import styles from './index.module.scss';

const BBFeaturedCategories = ({ heading, items }) => {
  return (
    <div className={`bb ${styles['bb-featured-categories']}`}>
      <div className="container">
        <h2 className={styles['bb-featured-categories__heading']}>{heading}</h2>
        <div className={styles['bb-featured-categories__items']}>
          {items.map((item, index) => (
            <div className={styles['bb-featured-categories__item']} key={index}>
              <Link {...item.link.nextLink}>
                <a>
                  <DefaultPicture
                    {...item.image}
                    className={styles['bb-featured-categories__item-image']}
                  />
                  <div
                    className={`${styles['bb-featured-categories__item-title']} ${item.color ? styles[`bb-featured-categories__item-title-${item.color}`] : ''}`}
                  >
                    {item.title}
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

BBFeaturedCategories.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.shape(pictureProps).isRequired,
      color: PropTypes.string,
      link: {
        nextLink: PropTypes.shape(linkPropTypes),
      },
    }),
  ).isRequired,
  heading: PropTypes.string.isRequired,
};

export default BBFeaturedCategories;
